import {
    AccountInfo,
    AuthenticationResult,
    EventMessage,
    InteractionStatus,
} from '@azure/msal-browser';
import {
    useIsAuthenticated,
    useMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import {
    getLogoutRequest,
    getOtpSignInRequest,
    getResetPasswordRequest,
    getSignInKompanAADRequest,
    getSignUpSignInSilentRequest,
    getSilentLogoutRequest,
    usePreviewModeState,
} from '..';
import { StorageKey, useStorage } from '$shared/hooks';
import { useRouter } from 'next/router';
import { setCookie } from 'cookies-next';
import { fetcher } from '~/lib/fetcher';
import { GetUserLoginFlowResponse } from '~/templates/pages/api/mykompan/getUserLoginFlow';

export type UserClaims = { [key: string]: unknown } & {
    emails: string[];
    extension_CompanyCode: string;
    extension_Features: string;
    extension_IsFromKompanAD: boolean;
    extension_KompanUserId: string;
    extension_NeedsResetPassword: boolean;
    extension_UserNo: string;
    extension_UserType: string;
    name: string;
};

export type LoginParams = {
    loginPageUrl: string;
    loginRedirectPageUrl: string;
    userEmail: string;
    redirectSpace?: string;
    state?: string;
};

export type LoginFlows = 'OtpSignIn' | 'ResetPassword' | 'KompanAADSignIn';

export const useAuth = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { isStorageSupported, setStorageKey } = useStorage();
    const router = useRouter();
    const { isPreviewUserAuthenticated, setIsPreviewUserAuthenticated } = usePreviewModeState();
    // Will be true while msal if veryfying the user. Should be true initially and turn false once user is correctly logged in.
    const isLoading = [
        InteractionStatus.Startup,
        InteractionStatus.Login,
        InteractionStatus.AcquireToken,
    ].find((x) => x == inProgress);

    const getAccount = (): AccountInfo | null => {
        const activeAccount = instance.getActiveAccount();
        if (!activeAccount) {
            const firstAccount = instance.getAllAccounts()?.[0];
            if (firstAccount) {
                instance.setActiveAccount(firstAccount);
            }
            return firstAccount;
        }
        return activeAccount;
    };

    const getClaims = (): UserClaims | undefined => {
        return getAccount()?.idTokenClaims as UserClaims;
    };

    const login = async ({
        loginRedirectPageUrl,
        userEmail,
        redirectSpace,
        state,
    }: LoginParams) => {
        if (redirectSpace) {
            setStorageKey(StorageKey.redirectSpace, redirectSpace, true);
        }

        const loginFlow = await getLoginFlow(userEmail);

        if (loginFlow === 'KompanAADSignIn') {
            await instance.loginRedirect(
                getSignInKompanAADRequest(userEmail, loginRedirectPageUrl, state),
            );
            return;
        }
        if (loginFlow === 'ResetPassword') {
            await instance.loginRedirect(
                getResetPasswordRequest({
                    loginRedirectPageUrl,
                    state: 'newUser=true',
                    loginHint: userEmail,
                }),
            );
            return;
        }

        // TODO: Figure out if we need the old SignUpSignIn method at all.
        await instance.loginRedirect(
            getOtpSignInRequest({ userEmail, loginRedirectPageUrl, state }),
        );
        return;
    };

    const forceReLogin = async ({
        userEmail,
        loginRedirectPageUrl,
    }: {
        userEmail: string;
        loginRedirectPageUrl: string;
    }) => {
        await instance.loginRedirect(
            getOtpSignInRequest({
                userEmail,
                loginRedirectPageUrl,
                prompt: 'login',
            }),
        );
    };

    const logout = async (loginPageUrl: string, redirectToLogin = true) => {
        if (isPreviewUserAuthenticated) {
            setIsPreviewUserAuthenticated(false);
            router.replace(`${loginPageUrl}`).finally();
        } else {
            await instance.logoutRedirect(getLogoutRequest(loginPageUrl, redirectToLogin));
        }
    };

    const silentLogout = async (redirectToPath?: string) => {
        await instance.logout(getSilentLogoutRequest(redirectToPath));
    };

    const resetPassword = async (loginRedirectPageUrl: string, state?: string) => {
        await instance.acquireTokenRedirect(
            getResetPasswordRequest({ loginRedirectPageUrl, state }),
        );
    };

    const refreshToken = async (loginPageUrl: string, loginRedirectPageUrl: string) => {
        // No token to refresh if we have a preview user.
        if (isPreviewUserAuthenticated) return;

        const account = getAccount();
        if (!account) {
            await logout(loginPageUrl);
            return;
        }

        const isTokenExpired =
            account?.idTokenClaims?.exp && account.idTokenClaims.exp * 1000 < Date.now();

        if (isTokenExpired) {
            try {
                const authenticationResult = await instance.acquireTokenSilent(
                    getSignUpSignInSilentRequest(loginRedirectPageUrl),
                );
                if (isStorageSupported()) {
                    setCookie(StorageKey.authToken, authenticationResult.idToken);
                }
            } catch (error) {
                await logout(loginPageUrl);
                return;
            }
        }
    };

    /**
     *
     * @param event MSAL event
     * @returns State object carried through login event
     */
    const extractPayloadState = (event: EventMessage) => {
        const fallbackValue = new URLSearchParams();
        if (!event.payload) return fallbackValue;
        const payload = event?.payload as AuthenticationResult;
        try {
            // Try getting forward page from before the user logged in
            const stateParams = new URLSearchParams(payload.state);
            return stateParams;
        } catch (err) {
            // If this fails, our initial timout will trigger after 2s and handle the redirect.
            console.error('extractPayloadStateError', err);
        }
        return fallbackValue;
    };

    /**
     *
     * @param email user email
     * @returns Enum for the Azure AD login flow
     */
    const getLoginFlow = async (email: string): Promise<LoginFlows> => {
        const defaultFlow = 'OtpSignIn';
        try {
            const response = await fetcher<GetUserLoginFlowResponse>(
                `/api/auth/getUserLoginFlow`,
                {},
                {},
                'POST',
                JSON.stringify({ email }),
            ).then((res) => res.json());
            return response.success ? response.loginFlow : defaultFlow;
        } catch (err) {
            return defaultFlow;
        }
    };
    return {
        instance,
        isLoading,
        inProgress,
        isAuthenticated,
        getAccount,
        getClaims,
        login,
        logout,
        forceReLogin,
        resetPassword,
        refreshToken,
        AuthenticatedTemplate,
        UnauthenticatedTemplate,
        silentLogout,
        extractPayloadState,
    };
};
